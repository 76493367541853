<template>
    <!-- width="1600px" height="2844px"  -->
    <!-- >= 1000 width a >= 1900 height -->
    <div class="content has-text-centered fondo" :class="{'fondo':!isMobile}">
        <img :src="img" alt="" :class="{'url-image':isMobile,'url-image-desktop':!isMobile}">
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { GraphQLClient } from 'graphql-request'
import store from '@/store';

export default {

    data: () => ({
        isMobile : false
    }),

    created(){
        this.traerImagen()
        store.commit("onResize")
        this.isMobile = store.state.isMobile
    },

    setup(){
        const route = useRoute();
        const img = ref('')
        const endpoint = store.state.url_backend

        const traerImagen = () => {
            const client = new GraphQLClient(endpoint)
            client.rawRequest(/* GraphQL */ `
            mutation($puntowifi_id: ID!){
                selecAnuncioPunto(puntowifi_id: $puntowifi_id)
            }`,
            {
                puntowifi_id: route.params.id
            },
            {
            })
            .then((data) => {
                // console.log(data)
                img.value = data.data.selecAnuncioPunto
            })
            .catch(error => {
                // console.log(error.response)
            })
        }

        return{
            img,
            traerImagen,
        }
    }
}
</script>

<style scoped>

.url-image{
    max-width: 100%;
    height: auto;
}


.url-image-desktop{
    max-width: 100%;
    height: 700px;
}

.fondo{
    background-color: rgba(10, 10, 10, 0.86);
}

</style>